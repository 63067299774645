
// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MoleculeInfoTile extends Vue {
  @Prop({ type: String, default: '' }) readonly icon!: string;

  @Prop({ type: String, default: '' }) readonly title!: string;

  @Prop({ type: String, default: '' }) readonly body!: string;

  get iconClasses() {
    return {
      'me-4 molecule-info-tile__icon': true,
      [this.icon]: this.icon,
    };
  }
}
