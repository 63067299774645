
// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AtomSection extends Vue {
  @Prop({ type: [Number, String], default: 96 }) readonly space!: number | string;

  get styles() {
    const space = this.$breakpoint.mdAndUp ? this.space : Number(this.space) / 2;

    return {
      'padding-top': `${space}px`,
      'padding-bottom': `${space}px`,
    };
  }
}
