
// libraries
import { Component, Vue } from 'vue-property-decorator';

// types
import { Routes } from '@/router/routes';

@Component
export default class HomeIntro extends Vue {
  servicesRoute = Routes.Services;
}
