
// libraries
import { Component, Vue } from 'vue-property-decorator';

// types
import { Routes } from '@/router/routes';

@Component
export default class ErrorView extends Vue {
  homepageRoute = Routes.Home;

  errorMessage = 'Page 404 - Seite nicht gefunden';
  buttonLabel = 'zurück zur Startseite';

  get errorHeading() {
    return this.errorMessage;
  }
  get ctaText() {
    return this.buttonLabel;
  }
}
