
// libraries
import { Component, Vue } from 'vue-property-decorator';

// models
import Stack from '@/models/Stack';

@Component
export default class App extends Vue {
  locationSrc = require('@/assets/images/location.svg');

  created() {
    Stack.$api().index();
  }
}
