
// libraries
import { Component, Vue, Watch } from 'vue-property-decorator';

// types
import { Routes } from '@/router/routes';
import { ClassEnum as CollapseClassEnum, EventEnum as CollapseEventEnum } from '@/directives/partials/collapse';

// utils
import { executeAfterTransition } from '@/utils/general';
import ScrollHandler from '@/utils/scroll';

@Component
export default class OrganismHeader extends Vue {
  scrollHandler = new ScrollHandler();

  homeRoute = Routes.Home;

  items = [
    {
      title: this.$t('header.services'),
      route: Routes.Services,
    },
    {
      title: this.$t('header.legal'),
      route: Routes.Legal,
    },
  ];

  logoSrc = require('@/assets/images/logo-pssg.svg');

  isOpen = false;

  isAnimating = false;

  mounted() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.onScroll);
  }

  beforeDestory() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    if (this.scrollHandler.scrollYOld < 0) {
      return;
    }
    const header = this.$el as HTMLElement;

    if (this.scrollHandler.isScrollBottom && this.scrollHandler.scrollYOld > 0) {
      header.style.top = `${-header.offsetHeight}px`;
      header.style.borderBottomColor = '#e9ecef';
    } else if (this.scrollHandler.isScrollBottom && this.scrollHandler.scrollYOld == 0) {
      header.style.top = `${-header.offsetHeight}px`;
      header.style.borderBottomColor = '#ffffff';
    } else if (this.scrollHandler.isScrollTop && this.scrollHandler.scrollYOld > 0) {
      header.style.top = String(0);
      header.style.borderBottomColor = '#e9ecef';
    } else if (this.scrollHandler.isScrollTop && this.scrollHandler.scrollYOld == 0) {
      header.style.top = String(0);
      header.style.borderBottomColor = '#ffffff';
    }
  }

  openFlyout() {
    this.$backdrop.show();

    if (this.$breakpoint.lgAndDown) {
      (this.$refs.headerToggle as HTMLElement).dispatchEvent(new Event(CollapseEventEnum.OpenTarget));
    }

    document.documentElement.classList.add('overflow-y-hidden');

    this.isOpen = true;

    (this.$backdrop.element as HTMLElement).addEventListener('click', this.onToggle);
  }

  hideFlyout() {
    this.$backdrop.hide();

    if (this.$breakpoint.lgAndDown) {
      (this.$refs.headerToggle as HTMLElement).dispatchEvent(new Event(CollapseEventEnum.HideTarget));
    }

    document.documentElement.classList.remove('overflow-y-hidden');

    this.isOpen = false;

    (this.$backdrop.element as HTMLElement).removeEventListener('click', this.onToggle);
  }

  onToggle() {
    if ((this.$refs.headerContent as HTMLElement).classList.contains(CollapseClassEnum.Collapsing)) {
      return;
    }

    if (this.isOpen && this.$backdrop.element) {
      this.hideFlyout();
    } else if (!this.isOpen && !this.$backdrop.element) {
      this.openFlyout();
    }
  }

  onResize() {
    if (this.isOpen && this.$breakpoint.lgAndUp) {
      const content = this.$refs.headerContent as HTMLElement;

      const complete = () => {
        content.classList.remove(CollapseClassEnum.Collapse, CollapseClassEnum.Open);
        content.style.transition = '';
        content.style.height = '';
      };

      executeAfterTransition(complete, content, true);

      this.hideFlyout();
    }
  }

  @Watch('$route')
  onChangeRoute() {
    if (this.isOpen) this.hideFlyout();
  }
}
