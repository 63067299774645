declare global {
  interface Window {
    _paq: any;
  }
}

import '@/directives';
import '@/registerServiceWorker';
import 'bootstrap';
import App from '@/App.vue';
import axios from '@/plugins/axios';
import backdrop from '@/plugins/backdrop';
import breakpoint from '@/plugins/breakpoint';
import i18n from '@/plugins/i18n';
import router from '@/router';
import store from '@/store';
import Vue from 'vue';
import VueRouterMultiView from 'vue-router-multi-view';
import VueScrollTo from 'vue-scrollto';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import '@/components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import VueMatomo from 'vue-matomo';

library.add(faUserSecret);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.config.productionTip = false;

Vue.use(axios);
Vue.use(backdrop);
Vue.use(breakpoint);
Vue.use(VueRouterMultiView);
Vue.use(VueMatomo, {
  host: 'https://matomo.pssg-solutions.de',
  siteId: 1,
  router: router,
  domains: '*.pssg-solutions.de',
  requireCookieConsent: true,
});

Vue.use(VueScrollTo, {
  duration: 0,
});

VuexORM.use(VuexORMAxios, {
  axios: Vue.prototype.$api,
});

new Vue({
  router,
  store: store.original,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
