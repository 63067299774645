
// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

// models
import Stack from '@/models/Stack';

@Component
export default class StackIcon extends Vue {
  @Prop({ type: Object, required: true }) readonly stack!: Stack;
}
