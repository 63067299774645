
// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MoleculeMemberAvatar extends Vue {
  @Prop({ type: String, required: true }) readonly image!: string;

  @Prop({ type: String, required: false }) readonly imageClass?: string;

  @Prop({ type: String, default: '' }) readonly title!: string;

  @Prop({ type: String, default: '' }) readonly text!: string;
}
