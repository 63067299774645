
// libraries
import { Component, Vue } from 'vue-property-decorator';

// components
import HomeIntro from './partials/HomeIntro.vue';
import HomePhone from './partials/HomePhone.vue';
import HomeYourPartner from './partials/HomeYourPartner.vue';
import HomeOutro from './partials/HomeOutro.vue';

@Component({
  components: {
    HomeIntro,
    HomePhone,
    HomeYourPartner,
    HomeOutro,
  },
})
export default class HomeView extends Vue {}
