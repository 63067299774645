
// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

// types
import { ClassEnum as CollapseClassEnum, EventEnum as CollapseEventEnum } from '@/directives/partials/collapse';

@Component
export default class MoleculeCollapsibleCard extends Vue {
  @Prop({ type: String, required: true }) readonly image!: string;

  @Prop({ type: String, required: true }) readonly title!: string;

  @Prop({ type: String, required: true }) readonly subTitle!: string;

  @Prop({ type: String, required: true }) readonly content!: string;

  @Prop({ type: String, required: true }) readonly ctaText!: string;

  isMounted = false;

  isOpen = false;

  get icon() {
    return this.isOpen ? 'arrow-up2' : 'arrow-down2';
  }

  get iconAnination() {
    return this.isOpen ? 'top' : 'bottom';
  }

  mounted() {
    this.isMounted = true;

    window.addEventListener(CollapseEventEnum.Resize, this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener(CollapseEventEnum.Resize, this.onResize);
  }

  onResize() {
    this.isOpen = false;
  }

  onToggle() {
    if ((this.$refs.cardContent as HTMLElement).classList.contains(CollapseClassEnum.Collapsing)) {
      return;
    }

    if (this.isOpen) {
      (this.$refs.cardTrigger as HTMLElement).dispatchEvent(new Event(CollapseEventEnum.HideTarget));

      this.isOpen = false;
    } else {
      (this.$refs.cardTrigger as HTMLElement).dispatchEvent(new Event(CollapseEventEnum.OpenTarget));

      this.isOpen = true;
    }
  }
}
