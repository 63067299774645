
// libraries
import { Component, Vue } from 'vue-property-decorator';

// components
import InlineSvg from 'vue-inline-svg';

@Component({
  components: {
    InlineSvg,
  },
})
export default class HomePhone extends Vue {}
