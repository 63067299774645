
// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

// types
enum ColorEnum {
  Primary = 'primary',
}

@Component
export default class AtomBurger extends Vue {
  @Prop({ type: String, default: '30px' }) readonly size!: string;

  @Prop({
    type: String,
    default: ColorEnum.Primary,
    validator: (value: ColorEnum) => [ColorEnum.Primary].includes(value),
  })
  readonly color!: string;

  get styles() {
    return {
      height: this.size,
      width: this.size,
    };
  }

  get classes() {
    return {
      'atom-burger': true,
      [`stroke-color--${this.color}`]: !!this.color,
    };
  }
}
