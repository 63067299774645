// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

// enums
enum ColorEnum {
  dark = 'dark',
  gray = 'gray',
  light = 'light',
  primary = 'primary',
  secondary = 'secondary',
}

// utils
import { colors } from '@/utils/color';

const validateSize = (value: string) => {
  return value.includes('%') || value.includes('px') || value.includes('em') || value.includes('rem');
};

@Component
export default class ShadowMixin extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly shadow!: boolean;

  @Prop({
    type: String,
    default: '20px',
    validator: (value) => validateSize(value as any),
  })
  readonly shadowRight!: string;

  @Prop({
    type: String,
    default: '20px',
    validator: (value) => validateSize(value as any),
  })
  readonly shadowDown!: string;

  @Prop({
    type: String,
    default: '0px',
    validator: (value) => validateSize(value as any),
  })
  readonly shadowBlur!: string;

  @Prop({
    type: String,
    default: '0px',
    validator: (value) => validateSize(value as any),
  })
  readonly shadowSpread!: string;

  @Prop({
    type: String,
    default: ColorEnum.gray,
    validator: (value) => {
      return Object.keys(colors).includes(value as any);
    },
  })
  readonly shadowColor!: ColorEnum;

  get shadowMixinStyles() {
    return {
      'box-shadow': this.shadow
        ? `${this.shadowRight} ${this.shadowDown} ${this.shadowBlur} ${this.shadowSpread} ${colors[this.shadowColor]}`
        : undefined,
    };
  }
}
