// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

// enums
enum AlignEnum {
  center = 'center',
  end = 'end',
  start = 'start',
}

enum ColorEnum {
  dark = 'dark',
  gray = 'gray',
  light = 'light',
  primary = 'primary',
  secondary = 'secondary',
}

enum FontWeightEnum {
  bold = 'bold',
  normal = 'normal',
  italic = 'italic',
}

export enum HeadlineEnum {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

@Component
export default class TypoMixin extends Vue {
  @Prop({
    type: String,
    default: AlignEnum.start,
    validator: (value) => {
      return [AlignEnum.start, AlignEnum.center, AlignEnum.end].includes(value as any);
    },
  })
  readonly align!: AlignEnum;

  @Prop({
    type: String,
    default: ColorEnum.secondary,
    validator: (value) => {
      return [ColorEnum.primary, ColorEnum.secondary, ColorEnum.dark, ColorEnum.light, ColorEnum.gray].includes(
        value as any,
      );
    },
  })
  readonly color!: ColorEnum;

  @Prop({
    type: String,
    default: FontWeightEnum.normal,
    validator: (value) => {
      return [FontWeightEnum.bold, FontWeightEnum.normal].includes(value as any);
    },
  })
  readonly fontWeight!: FontWeightEnum;

  @Prop({
    type: String,
    default: 'normal',
  })
  readonly fontStyle!: string;

  @Prop({
    type: String,
    required: false,
    validator: (value) => {
      return [
        HeadlineEnum.h1,
        HeadlineEnum.h2,
        HeadlineEnum.h3,
        HeadlineEnum.h4,
        HeadlineEnum.h5,
        HeadlineEnum.h6,
      ].includes(value as any);
    },
  })
  readonly size!: HeadlineEnum;

  get typoMixinClasses() {
    return {
      [`font-style--${this.fontStyle}`]: true,
      [`font-weight--${this.fontWeight}`]: true,
      [`text-${this.align}`]: true,
      [`text-color--${this.color}`]: true,
      [this.size]: !!this.size,
    };
  }
}
