
// libraries
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';

// utils
import { truncate } from '@/utils/general';

// components
import StacksOverview from './partials/StacksOverview.vue';

@Component({
  components: {
    StacksOverview,
  },
})
export default class ServicesView extends Vue {
  services = [
    {
      image: require('@/assets/images/consulting.svg'),
      title: this.$t('views.services.items.consulting.title'),
      text: this.$t('views.services.items.consulting.text'),
      longtext: this.$t('views.services.items.consulting.longtext'),
    },
    {
      image: require('@/assets/images/monitors.svg'),
      title: this.$t('views.services.items.frontend.title'),
      text: this.$t('views.services.items.frontend.text'),
      longtext: this.$t('views.services.items.frontend.longtext'),
    },
    {
      image: require('@/assets/images/monitor.svg'),
      title: this.$t('views.services.items.backend.title'),
      text: this.$t('views.services.items.backend.text'),
      longtext: this.$t('views.services.items.backend.longtext'),
    },
    {
      image: require('@/assets/images/smartphone-person.svg'),
      title: this.$t('views.services.items.apps.title'),
      text: this.$t('views.services.items.apps.text'),
      longtext: this.$t('views.services.items.apps.longtext'),
    },
    {
      image: require('@/assets/images/concepting.svg'),
      title: this.$t('views.services.items.management.title'),
      text: this.$t('views.services.items.management.text'),
      longtext: this.$t('views.services.items.management.longtext'),
    },
    {
      image: require('@/assets/images/service-wartung.svg'),
      title: this.$t('views.services.items.servicewartungen.title'),
      text: this.$t('views.services.items.servicewartungen.text'),
      longtext: this.$t('views.services.items.servicewartungen.longtext'),
    },
    {
      image: require('@/assets/images/stacks.svg'),
      title: this.$t('views.services.items.stacks.title'),
      text: this.$t('views.services.items.stacks.text'),
      longtext: this.$t('views.services.items.stacks.longtext'),
    },
  ];

  getCardClasses(index: number) {
    let marginClass = '';

    if (this.$breakpoint.lgAndUp) {
      if (index % 3 === 0) {
        marginClass = 'me-auto';
      } else if ((index + 1) % 3 === 0) {
        marginClass = 'ms-auto';
      } else {
        marginClass = 'mx-auto';
      }
    }

    return {
      'service-tile-wrapper h-100 ': true,
      [marginClass]: !!marginClass,
    };
  }

  getTeaserClasses() {
    return {
      'mb-5 ': true,
    };
  }

  getTeaserOrder(index: number) {
    if (this.$breakpoint.lgAndUp) return index % 2 === 0;

    return true;
  }

  getTruncatedCardText(str: string) {
    return truncate(str, 142);
  }

  onClickCardLink(_event: PointerEvent, index: number) {
    const element = (this.$refs[`serviceTeaser-${index}`] as any)[0].$el;

    this.$scrollTo(element);
  }
}
