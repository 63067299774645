
// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';

// types
enum ColorEnum {
  Primary = 'primary',
}

@Component
export default class MoleculeBurgerButton extends Vue {
  @Prop({
    type: String,
    default: ColorEnum.Primary,
    validator: (value: ColorEnum) => [ColorEnum.Primary].includes(value),
  })
  readonly color!: string;

  get classes() {
    return {
      'molecule-burger-button': true,
      [`border-color--${this.color}`]: !!this.color,
    };
  }
}
