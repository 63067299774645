
// libraries
import { Component, Vue } from 'vue-property-decorator';

// types
import { Routes } from '@/router/routes';

@Component
export default class MainLayout extends Vue {
  routes = [
    //
    Routes.Home,
    Routes.Services,
  ];

  onChangeTab(route: Routes) {
    this.$router.push({ name: route });
  }
}
