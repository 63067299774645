
// libraries
import { Component, Vue, Prop } from 'vue-property-decorator';
import lozad from 'lozad';

@Component
export default class AtomImage extends Vue {
  @Prop({ type: String, default: '' }) readonly lazySrc!: string;

  @Prop({ type: String, default: '' }) readonly lazySrcset!: string;

  @Prop({ type: Boolean, default: false }) readonly lazyLoad!: boolean;

  @Prop({ type: Boolean, default: false }) readonly rounded!: boolean;

  @Prop({ type: String, default: '' }) readonly alt!: string;

  mounted() {
    const observer = lozad(this.$el);

    if (this.lazyLoad) {
      observer.observe();
    } else {
      observer.triggerLoad(this.$el);
    }
  }

  get classes() {
    return {
      'atom-image': true,
      ['atom-image--rounded']: this.rounded,
    };
  }
}
