// libraries
import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';

// models
import Stack from '@/models/Stack';

Vue.use(Vuex);

// create a new instance of database
const database = new VuexORM.Database();

// register models to database
database.register(Stack);

// direct vuex store
const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
  actions: {},
  mutations: {},
  modules: {},
  plugins: [VuexORM.install(database)],
});

// exports
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };

export default store;

// options
type AppStore = typeof store;

declare module 'vuex' {
  interface Store<S> {
    direct: AppStore;
  }
}
